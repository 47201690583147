import React from 'react';
import {RecoilRoot, useRecoilValue} from 'recoil';
import {QueryClientProvider} from 'react-query';
import {BrowserRouter} from 'react-router-dom';

import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';

import Snackbar from 'containers/Snackbar';
import ScrollTop from 'containers/ScrollTop';
import AlertDialog from 'containers/Dialog';

import queryClient from './plugins/react-query/queryClient';
import AxiosRequestObserver from './plugins/axios/RequestObserver';
import AnalyticsTracker from './plugins/tracker/AnalyticsTracker';

import Routes from './Routes';

import './plugins/react-query/setMutationDefaults';
import theme from '@un7qi3-ui/material-theming';
import ErrorBoundary from '../containers/ErrorBoundary';

export default React.memo(() => {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <RecoilRoot>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
              <ThemeProvider theme={theme}>
                <Routes />
              </ThemeProvider>
            </ErrorBoundary>

            <AlertDialog />
            <Snackbar />
            <AnalyticsTracker />
            <AxiosRequestObserver />

            <ScrollTop />
          </QueryClientProvider>
        </RecoilRoot>
      </BrowserRouter>
    </StyledEngineProvider>
  );
});
